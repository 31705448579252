import { Routes } from '@angular/router';

import { PageResolve } from '@nghedgehog/angular-ui';

import { HomeComponent } from './home.component';
import { routes as indexRoutes } from './routes/index/index.routes';

export const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    data: {
      title: '首頁',
    },
    children: [
      {
        path: '',
        loadChildren: () => indexRoutes,
      },
      {
        path: 'live-area',
        loadChildren: () =>
          import('./routes/live-area/live-area.routes').then((m) => m.routes),
      },
      {
        path: 'community',
        loadChildren: () =>
          import('./routes/community/community.routes').then((m) => m.routes),
      },
      {
        path: 'company',
        loadChildren: () =>
          import('./routes/company/company.routes').then((m) => m.routes),
      },
      {
        path: 'about',
        loadChildren: () =>
          import('./routes/about/about.routes').then((m) => m.routes),
      },
      {
        path: 'properties',
        loadChildren: () =>
          import('./routes/property/property.routes').then((m) => m.routes),
      },
      {
        path: 'entrust',
        loadChildren: () =>
          import('./routes/property-entrust/property-entrust.routes').then(
            (m) => m.routes,
          ),
        resolve: { page: PageResolve },
      },
      {
        path: 'blog',
        loadChildren: () =>
          import('./routes/blog/blog.routes').then((m) => m.routes),
      },
      {
        path: 'page',
        loadChildren: () =>
          import('./routes/other-page/other-page.routes').then((m) => m.routes),
      },
      {
        path: 'calculator',
        loadChildren: () =>
          import('./routes/calculator/calculator.routes').then((m) => m.routes),
      },
      {
        path: 'tags',
        loadChildren: () =>
          import('./routes/tag/tag.routes').then((m) => m.routes),
      },
    ],
  },
];
