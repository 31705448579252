import { Inject, Injectable, InjectionToken, inject } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { ICache, storage } from '@nghedgehog/angular-ui';

export const THEME_COLOR_LIST = new InjectionToken<ThemeModel[]>(
  'THEME_COLOR_LIST',
);

export interface ThemeModel {
  name: string;
  isLoad: boolean;
  icon?: string;
  primary: string;
  loadChildren: () => Promise<{ Component: any }>;
}

@Injectable({
  providedIn: 'root',
})
export class ThemeService implements ICache {
  public themes = inject<ThemeModel[]>(THEME_COLOR_LIST, { optional: true });
  readonly storageKey = 'ThemeService';

  currentTheme$ = new BehaviorSubject<ThemeModel>(this.themes?.[0]);

  @storage()
  themeType = this.currentTheme$.value?.name;

  init() {
    this.setTheme(this.getTheme(this.themeType));
  }

  setTheme(themeSource: ThemeModel | string) {
    let theme: ThemeModel;

    if (typeof themeSource === 'string') {
      theme = this.getTheme(themeSource);
    } else {
      theme = themeSource;
    }

    if (theme) {
      this.currentTheme$.next(theme);
      this.themeType = theme.name;
    }
  }

  private getTheme(nowTheme: string) {
    let selectTheme: ThemeModel;
    if (nowTheme) {
      selectTheme = this.themes.find((theme) => theme.name === nowTheme);
    }
    // if can't find theme, use the default theme
    if (!selectTheme) {
      selectTheme = this.themes[0];
    }
    return selectTheme;
  }
}
