import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-my-close',
  templateUrl: './my-close.component.html',
  styleUrls: ['./my-close.component.scss'],
  standalone: true,
  imports: [MatButtonModule, MatIconModule],
})
export class MyCloseComponent {}
