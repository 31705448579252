import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Routes,
} from '@angular/router';

import { IndexComponent } from './index.component';

import { inject } from '@angular/core';
import { PropertyService } from '../../../../core/service/property.service';
import { AwooService } from '../../../../core/service/awoo.service';

const carouselProvider = 'properties';

export const routes: Routes = [
  {
    path: '',
    component: IndexComponent,
    providers: [
      {
        provide: carouselProvider,
        useFactory: (_property: PropertyService) => () => {
          return _property.getHomeCarouselList();
        },
        deps: [PropertyService],
      },
    ],
    resolve: {
      carousel: carouselProvider,
      popularTags: (
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
      ) => {
        const awoo = inject(AwooService);

        return awoo.popularTags$;
      },
    },
  },
];
