import { Component, forwardRef, Input, OnInit, inject } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';

import { MenuModel } from '@alan-apps/data-access';
import { BaseService, I18nLinkPipe } from '@nghedgehog/angular-ui';
import { TranslateModule } from '@ngx-translate/core';
import { input } from '@angular/core';

@Component({
  selector: '[footer-link]',
  templateUrl: './footer-link.component.html',
  styleUrls: ['./footer-link.component.scss'],
  standalone: true,
  imports: [
    RouterLinkActive,
    I18nLinkPipe,
    RouterLink,
    forwardRef(() => FooterLinkComponent),
    TranslateModule,
  ],
})
export class FooterLinkComponent implements OnInit {
  public _base = inject(BaseService);
  menu = input<MenuModel | undefined>();

  hasChild = false;

  get children() {
    return this.menu().children.filter((child) => !child.hiddenInFooter);
  }

  ngOnInit() {
    this.hasChild = this.menu().children && this.menu().children.length > 0;
  }
}
