import { NgModule } from '@angular/core';

import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import extractFiles from 'extract-files/extractFiles.mjs';
import isExtractableFile from 'extract-files/isExtractableFile.mjs';

import {
  ApolloClientOptions,
  ApolloLink,
  DefaultOptions,
  from,
  InMemoryCache,
} from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { constants } from '../environments/constants';
import { environment } from '../environments/environment';
import { AuthService, I18nService } from '@nghedgehog/angular-ui';

const uri = '/graphql';

// TODO: should open cache after ready
const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      const { message, locations, path, extensions } = error;
      const { code } = extensions || {};
      console.log('🚀 ~ error', code, error);
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      );
    });
  }

  if (networkError) {
    if (!environment.production) {
      console.log('🚀 ~ networkError', networkError);
      const errors = networkError['error'].errors as any[];

      // const message =
      errors?.forEach(({ message }) => {
        console.error(message);
        // return message;
      });
      // alert(message.join('\n\n\n\n'));
    }

    console.error(`[Network error]`, networkError);
  }
});

export function createApollo(
  httpLink: HttpLink,
  authService: AuthService,
  i18nService: I18nService,
): ApolloClientOptions<any> {
  // const basic = setContext((operation, context) => ({
  //   headers: {
  //     Accept: 'charset=utf-8',
  //   },
  // }));
  // basic,

  const auth = setContext((operation, context) => {
    const result = { headers: {} };
    const lang = i18nService.language;

    if (lang) {
      result.headers['lang'] = lang;
    }

    const token = authService;

    if (token !== null) {
      result.headers[
        'Authorization'
      ] = `${constants.tokenKey} ${authService.token}`;
    }
    return result;
  });

  const link = from([
    errorLink,
    ApolloLink.from([
      auth,
      httpLink.create({
        uri,
        extractFiles: (body) => {
          const result = extractFiles(body, isExtractableFile, '');

          return result;
        },
      }),
    ]),
  ]);

  const cache = new InMemoryCache({
    addTypename: false,
  });

  return {
    link,
    cache,
    defaultOptions,
  } as ApolloClientOptions<any>;
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, AuthService, I18nService],
    },
  ],
})
export class GraphQLModule {}
