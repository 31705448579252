/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck

import { environment } from './environments/environment';

export function initGTM() {
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    const f = d.getElementsByTagName(s)[0],
      j = d.createElement(s) as HTMLScriptElement,
      dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', 'GTM-WTW43JJZ');
}

export function initGoogleAnalytics() {
  //
}

export const FB_BadgeColor = '#ffc300';

export function initFBChat() {
  const page_id = environment.fbAppId;
  const theme_color = FB_BadgeColor;
  const logged_in_greeting = '您好，有什麼需要服務的嗎？';
  const logged_out_greeting = '您好，有什麼需要服務的嗎？';
  const greeting_dialog_display = 'hide';

  const root = document.createElement('div');
  root.id = 'fb-root';
  document.body.appendChild(root);

  const chatBox = document.createElement('div');
  chatBox.className = 'fb-customerchat';
  chatBox.setAttribute('attribution', 'biz_inbox');
  chatBox.setAttribute('page_id', page_id);
  chatBox.setAttribute('theme_color', theme_color);
  chatBox.setAttribute('logged_in_greeting', logged_in_greeting);
  chatBox.setAttribute('logged_out_greeting', logged_out_greeting);
  chatBox.setAttribute('greeting_dialog_display', greeting_dialog_display);
  document.body.appendChild(chatBox);

  // @ts-ignore
  window.fbAsyncInit = function () {
    FB.init({
      xfbml: true,
      version: 'v15.0',
    });
  };

  (function (d, s, id) {
    // eslint-disable-next-line no-var
    var js,
      fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    js = d.createElement(s);
    js.id = id;
    js.src = 'https://connect.facebook.net/zh_TW/sdk/xfbml.customerchat.js';
    fjs.parentNode.insertBefore(js, fjs);
  })(document, 'script', 'facebook-jssdk');
}
