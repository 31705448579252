<a
  [routerLink]="menu().url | i18nLink"
  [queryParams]="menu().queryParams"
  routerLinkActive="active"
  [class.has_child]="hasChild"
  class="relative"
  (click)="_base.toggleMenu()"
>
  {{ menu().title | translate }}
  @if (hasChild) {
    <i class="material-icons text-base -mt-[3px]">arrow_drop_down</i>
  }
</a>

<!-- pointer-events-none to prevent click when in small device -->
<div class="absolute top-0 right-0 bottom-0 left-0 mask" (click)="on()"></div>

@if (hasChild) {
  <ul
    class="elevation-6"
    [ngClass]="{ 'overflow-auto max-h-[50vh]': menu().children.length >= 8 }"
  >
    @for (menuItem of menu().children; track menuItem) {
      <li drop-down-item [menu]="menuItem"></li>
    }
  </ul>
}
