import 'hammerjs';

import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';

import * as Sentry from '@sentry/angular-ivy';

import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { environment } from './environments/environment';
import { initGoogleAnalytics, initGTM } from './initiator';
import { migration } from './migration';

if (environment.production) {
  Sentry.init({
    dsn: 'https://a0e733b347fa7f85b4be52fcfa48aeb7@o4505625092292608.ingest.sentry.io/4505625093931008',
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', environment.siteURL],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  enableProdMode();

  if (!environment.preview) {
    console.log('init google tag manager and google analytics');
    initGTM();
    initGoogleAnalytics();
  }

  migration();
}

bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err),
);

// back scroll position when refresh strategy
// document.addEventListener('DOMContentLoaded', function (event) {
//   var scrollpos = sessionStorage.getItem('scrollpos');
//   console.log('🐞 ~ scrollpos:', scrollpos);
//   if (scrollpos) {
//     setTimeout(() => {
//       console.log('🐞 ~ scrollpos:', scrollpos);
//       window.scrollTo(0, scrollpos);
//       sessionStorage.removeItem('scrollpos');
//     }, 1000);
//   }
// });

// window.addEventListener('beforeunload', function (e) {
//   sessionStorage.setItem('scrollpos', window.scrollY);
// });
