import { DOCUMENT } from '@angular/common';
import { Component, Input, NgZone, OnInit, inject } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';

import { filter, map, Observable, startWith, switchMap, tap } from 'rxjs';

import { MenuModel } from '@alan-apps/data-access';
import { I18nService, useOnBrowser, ViewService } from '@nghedgehog/angular-ui';
import { useTakeUntilDestroyed } from '@nghedgehog/core';

import { zoneCompare } from '../../../../core/decorators/zone.compare.decorator';
import { HomeMenuComponent } from '../home-menu/home-menu.component';
import { input } from '@angular/core';

@Component({
  selector: 'apps-float-menu',
  templateUrl: './float-menu.component.html',
  styleUrls: ['./float-menu.component.scss'],
  standalone: true,
  imports: [HomeMenuComponent],
})
export class FloatMenuComponent implements OnInit {
  public document = inject<Document>(DOCUMENT);
  private _view = inject(ViewService);
  private _router = inject(Router);
  public _zone = inject(NgZone);
  private _i18n = inject(I18nService);
  readonly takeUntilAppDestroy = useTakeUntilDestroyed();

  menus = input<MenuModel[] | undefined>();

  isAni = false;

  @zoneCompare
  isShowTop = false;

  @zoneCompare
  isSmallMenu = false;

  @zoneCompare
  isFixedMenu = false;

  topBtnObs$: Observable<{ state: boolean; top: number }>;

  isHome = true;

  private topArr = [0, 0];
  private saveDirection = true;

  ngOnInit(): void {
    this._router.events
      .pipe(
        filter(
          (e) =>
            e instanceof NavigationEnd ||
            // back to prev page
            (e instanceof NavigationStart &&
              e.navigationTrigger === 'popstate'),
        ),
        map((e) => e['urlAfterRedirects'] ?? e['url']),
        startWith(this._router.url),
        tap(() => {
          this.topArr = [0, 0];
          this.saveDirection = true;
        }),
        tap((url) => {
          this.isHome =
            beHome(url, '/') ||
            beHome(url, '/ja') ||
            beHome(url, '/en') ||
            beHome(url, '/zh');
        }),
        this.takeUntilAppDestroy(),
      )
      .subscribe();

    this.bindScroll();
  }

  private bindScroll = useOnBrowser(() => {
    this._zone.runOutsideAngular(() => {
      this._view
        .setMainView(this.document as any)
        .pipe(
          tap((top) => {
            if (top === 0) {
              this.topArr[1] = 0;
              this.isSmallMenu = this.isFixedMenu = this.isShowTop = false;
            }
          }),
          filter((top) => {
            if (this.isHome || top > 80) {
              return true;
            }
            return false;
          }),
          filter((top) => {
            const disD = top - this.topArr[1];
            this.topArr[0] = this.topArr[1];
            this.topArr[1] = top;
            const nowDirection = disD < 0;
            const dirChange = this.saveDirection !== nowDirection;
            this.saveDirection = nowDirection;
            return dirChange || top === 0;
          }),
          map((nowTop) => {
            this.isAni = this.topArr[0] !== 0 || this.isHome;
            this.topArr[0] = this.topArr[1];
            return this.saveDirection && this.isAni;
          }),
          tap((direction) => {
            this.isFixedMenu = true;
            this.isSmallMenu = !direction && !this.isHome;
            this.isShowTop = direction;
          }),
          switchMap(() => {
            return this._i18n.languageChange$.pipe(
              tap((lang) => {
                // TODO: should fix those logic, that will get flash when language change
                setTimeout(() => {
                  this.topArr = [0, 0];
                  this.isSmallMenu = this.isFixedMenu = this.isShowTop = false;
                  this.isAni = false;
                  this.saveDirection = true;
                }, 100);
              }),
            );
          }),
          this.takeUntilAppDestroy(),
        )
        .subscribe();
    });
  });
}
function beHome(source: string, url: string): boolean {
  return source === `${url}` || source.startsWith(`${url}?`);
}
