import { ActivatedRouteSnapshot, Routes } from '@angular/router';

import { routes as homeRoutes } from './pages/home/home.routes';
import {
  AuthGuard,
  ReloadComponent,
  RolePermissionFn,
  firstI18nGuard,
  i18nGuard,
  roleGuard,
  userValidateGuard,
} from '@nghedgehog/angular-ui';
import { styleGuard } from './core/guard/style.guard';
import { AboutService } from './core/service/about.service';

const homeData = 'homeData';

const homeRoute = {
  path: '',
  canActivate: [i18nGuard, userValidateGuard],
  children: [
    {
      path: '',
      loadChildren: () => homeRoutes,
      data: {
        rootURL: '/',
      },
    },
    {
      path: 'print',
      loadChildren: () =>
        import('./pages/print/print.routes').then((m) => m.routes),
    },
  ],
};

export const routes: Routes = [
  {
    path: 'reload',
    component: ReloadComponent,
  },
  {
    path: '',
    canActivate: [firstI18nGuard, styleGuard],
    resolve: {
      data: homeData,
    },
    providers: [
      {
        provide: homeData,
        useFactory:
          (_service: AboutService) => (route: ActivatedRouteSnapshot) => {
            return _service.homeData();
          },
        deps: [AboutService],
      },
    ],
    children: [
      homeRoute,
      {
        path: '',
        canActivate: [userValidateGuard],
        data: {
          userGuardCheck: true,
        },
        children: [
          {
            path: '',
            canActivate: [AuthGuard],
            children: [
              {
                path: 'member',
                loadChildren: () =>
                  import('./pages/member/member.routes').then((m) => m.routes),
                canActivate: [roleGuard],
                data: {
                  rootURL: '/member',
                  roleCheckFn: ((_auth) => {
                    return _auth.hasMemberPermission;
                  }) as RolePermissionFn,
                },
              },
              {
                path: 'manage',
                canActivate: [roleGuard],
                loadChildren: () =>
                  import('./pages/manage/manage.routes').then((m) => m.routes),
                data: {
                  rootURL: '/manage',
                  roleCheckFn: ((_auth) => {
                    return _auth.hasManagerPermission;
                  }) as RolePermissionFn,
                },
              },
            ],
          },
        ],
      },
      {
        path: 'auth',
        loadChildren: () =>
          import('./pages/auth/auth.routes').then((m) => m.routes),
      },
      {
        path: 'no-permissions',
        loadChildren: () =>
          import('./pages/no-permissions/no-permissions.routes').then(
            (m) => m.routes,
          ),
      },
      {
        ...homeRoute,
        path: ':lang',
      },
    ],
  },
  {
    path: '**',
    redirectTo: '',
  },
];
