@if (properties && properties.length > 0) {
  <div
    class="font-cwtexyen relative container grid grid-cols-1 lg:grid-cols-2 gap-2"
  >
    <div class="py-4 px-1 -my-6 -mx-5 sm:m-0">
      <div
        class="relative w-full overflow-hidden sm:rounded-2.5xl sm:ring sm:ring-primary sm:ring-offset-4 h-full"
      >
        <ngx-hm-carousel
          [(ngModel)]="currentIndex"
          (ngModelChange)="change(properties[$event])"
          [infinite]="true"
          align="left"
          aniClass="transition-long"
          class="carousel"
          [autoplay]="true"
          [autoplay-speed]="8000"
          [data]="properties"
        >
          <section ngx-hm-carousel-container class="content h-full">
            @for (property of properties; track property; let i = $index) {
              <article class="item h-full" ngx-hm-carousel-item>
                <img
                  class="object-cover non-drag w-full h-full mask-area aspect-[4/3]"
                  *ngxHmCarouselDynamic="
                    i;
                    length: properties.length;
                    index: currentIndex
                  "
                  [src]="
                    property.photo?.file
                      | img: 'maxresde'
                      | safe: 'url' : defaultImg
                  "
                  [attr.loading]="'lazy'"
                />
              </article>
            }
            <ng-template #infiniteContainer />
          </section>
          <ng-template #carouselContent let-property let-i="index">
            <article class="item h-full">
              <img
                class="object-cover non-drag w-full h-full mask-area aspect-[4/3]"
                *ngxHmCarouselDynamic="
                  i;
                  length: properties.length;
                  index: currentIndex
                "
                [src]="
                  property.photo?.file
                    | img: 'maxresde'
                    | safe: 'url' : defaultImg
                "
                [attr.loading]="'lazy'"
              />
            </article>
          </ng-template>
          <ng-template #carouselPrev>
            <div class="p-4 block lg:hidden">
              <i class="material-icons text-white">keyboard_arrow_left</i>
            </div>
          </ng-template>
          <ng-template #carouselNext>
            <div class="p-4 block lg:hidden">
              <i class="material-icons text-white">keyboard_arrow_right</i>
            </div>
          </ng-template>
        </ngx-hm-carousel>
        <!-- show when screen be mobile -->
        <div class="text-white lg:hidden">
          <div
            class="absolute justify-center flex flex-col top-0 bottom-0 w-full pointer-events-none mt-12"
          >
            <div
              class="container flex flex-col items-center lg:items-start text-shadow"
            >
              <h5>{{ current.name }}</h5>
              <h1 class="text-8xl text-primary font-normal">
                {{ (current.type ? '買' : '租') | translate
                }}{{ '好屋' | translate }}
              </h1>
              @if (text.length > 0) {
                <ngx-animate-text [textList]="text" />
              }
            </div>
          </div>
          <div class="absolute w-full text-center overflow-hidden bottom">
            <button
              mat-icon-button
              class="text-white next-animation font-bold example z-[4]"
              (click)="scroll(main)"
              aria-label="查看更多"
            >
              <mat-icon>keyboard_arrow_down</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col lg:py-4">
      <a
        class="custom hidden lg:block bg-primary rounded-2.5xl p-4 text-white whitespace-normal h-auto lg:mt-10 lg:translate-x-[-10%] lg:w-[110%] justify-start min-h-[200px] font-cwtexyen"
        mat-button
        [routerLink]="['/properties', current.id] | i18nLink"
      >
        <h3 class="text-[#000]/50 font-bold">{{ '主打物件' | translate }}</h3>
        <h2 class="text-3xl md:text-4xl lg:text-5xl font-bold text-shadow">
          {{ current.name }}
        </h2>
        <i class="flex-auto"></i>
        <div class="flex gap-2 items-end mt-4">
          <i class="flex-auto"></i>
          <b class="mt-3 text-2xl flex items-center justify-end">
            {{ '了解更多' | translate }}
            <i class="material-icons ml-2"> open_in_new </i>
          </b>
        </div>
      </a>
      <div class="hidden lg:block flex-auto">
        <div class="flex flex-col h-full justify-end mb-4">
          <i class="flex-auto"></i>
          <!-- <p>
          {{ current.fullAddressShort }}<br /><br />
          <span>
            {{ current.body_short }}
          </span>
        </p> -->
          <h2
            class="flex gap-3 flex-wrap justify-center lg:justify-end lg:pt-3 text-5xl [&>*]:mb-3"
          >
            <ng-template [ngTemplateOutlet]="patternTmpl" />
          </h2>
          <h2 class="text-center lg:text-right">
            @if (current.spaceNumber > 0) {
              <span>
                {{ '室內' | translate }}{{ current.spaceNumber
                }}{{ '坪' | translate }}
              </span>
            }
          </h2>
          <div class="flex gap-2 justify-center sm:justify-end mt-6">
            @for (property of properties; track property; let i = $index) {
              <div
                class="avatar-img bg-cover bg-no-repeat bg-center rounded-full cursor-pointer elevation-2"
                (click)="currentIndex = i"
                [ngClass]="{ 'border-primary border-3': currentIndex === i }"
                [style.backgroundImage]="
                  property.photo?.file
                    | img
                    | safe: 'background-image' : defaultImg
                "
              ></div>
            }
          </div>
        </div>
        <div
          class="absolute w-full text-center overflow-hidden bottom pointer-events-none lg-d:text-left"
        >
          <button
            mat-icon-button
            class="text-primary next-animation font-bold example z-[4] pointer-events-auto lg:translate-x-[60%]"
            (click)="scroll(main)"
            aria-label="查看更多"
          >
            <mat-icon>keyboard_arrow_down</mat-icon>
          </button>
        </div>
      </div>
      <!-- use for scroll into -->
      <div #main class="-translate-y-[60px]"></div>
    </div>
  </div>
}

<div class="py-6 bg-gray-50 relative flex flex-col-reverse">
  <div class="container relative">
    <div>
      <h3 class="md:w-1/2 lg:w-7/12">
        {{ current.name }}
      </h3>

      <h6 class="text-accent">
        {{ current.price }}{{ '元' | translate }}
        @if (!current.type) {
          /{{ '月' | translate }}
        }
      </h6>
      <p>
        {{ current.fullAddressShort }}<br /><br />
        <span>
          {{ current.body_short }}
        </span>
      </p>

      <ul class="flex flex-wrap gap-2 resize-area mt-3">
        <li class="lg:hidden">
          <span class="text-secondary">{{ '格局' | translate }}：</span>
          <ng-template [ngTemplateOutlet]="patternTmpl" />
        </li>
        <li>
          <span class="text-secondary">{{ '屋齡' | translate }}：</span>
          <span class="font-bold">{{ current.age }}</span>
        </li>
        @if (current.mainSpaceNumber) {
          <li>
            <span class="text-secondary">{{ '登記坪數' | translate }}：</span>
            <span class="font-bold"
              >{{ current.mainSpaceNumber }}{{ '坪' | translate }}</span
            >
          </li>
        }
      </ul>
    </div>
    <div class="block lg:hidden">
      <button
        mat-raised-button
        color="primary"
        class="text-white mt-3 w-full"
        [routerLink]="['/properties', current.id] | i18nLink"
      >
        {{ '了解更多' | translate }}
        <mat-icon>open_in_new</mat-icon>
      </button>
    </div>
  </div>
</div>

<ng-template #patternTmpl>
  @for (pattern of current.patterns; track pattern) {
    @if (pattern.amount > 0) {
      <span class="whitespace-nowrap inline-block">
        {{ pattern.amount }} {{ pattern.type | pattern | translate }}
      </span>
    }
  }
</ng-template>
