import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, OnInit, ViewEncapsulation, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute } from '@angular/router';
import { RouterLink } from '@angular/router';

import {
  NgxHmCarouselComponent,
  NgxHmCarouselDynamicDirective,
  NgxHmCarouselItemDirective,
} from 'ngx-hm-carousel';
import { combineLatest, firstValueFrom } from 'rxjs';

import { Property } from '@alan-apps/data-access';
import {
  I18nLinkPipe,
  LoadingBlockDirective,
  SafePipe,
  TypingAnimateTextComponent,
} from '@nghedgehog/angular-ui';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { ImgPipe } from '../../../../../core/pipe/img.pipe';
import { PatternPipe } from '../../../../../core/pipe/pattern.pipe';

@Component({
  selector: 'app-index-carousel',
  templateUrl: './index-carousel.component.html',
  styleUrls: ['./index-carousel.component.scss'],
  providers: [PatternPipe],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgxHmCarouselComponent,
    NgxHmCarouselItemDirective,
    NgxHmCarouselDynamicDirective,
    FormsModule,
    LoadingBlockDirective,
    MatButtonModule,
    MatIconModule,
    I18nLinkPipe,
    RouterLink,
    NgTemplateOutlet,
    NgClass,
    TranslateModule,
    PatternPipe,
    SafePipe,
    ImgPipe,
    TypingAnimateTextComponent,
  ],
})
export class IndexCarouselComponent implements OnInit {
  private _route = inject(ActivatedRoute);
  private _patternPipe = inject(PatternPipe);
  private _translate = inject(TranslateService);
  properties = this._route.snapshot.data['carousel'].nodes;
  defaultImg = 'assets/img/default_property.png';
  currentIndex = 0;

  text = [];

  cardBody = '';

  get current() {
    return this.properties[this.currentIndex];
  }

  ngOnInit(): void {
    if (this.properties.length > 0) {
      this.change(this.properties[0]);
    }
  }

  scroll(elm: HTMLElement) {
    elm.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  async change(current: Property) {
    const patterns = current.patterns.map((x) => {
      const val = this._patternPipe.transform(x.type);

      return this._translate.get(val);
    });

    const [mainI18n, goodI18n, pingI18n, ...patternList] = await firstValueFrom(
      combineLatest([
        this._translate.get('主打物件'),
        this._translate.get('優質物件'),
        this._translate.get('坪'),
        ...patterns,
      ]),
    );

    const pattern = current.patterns
      .reduce((acc, curr, i) => {
        if (curr.amount) {
          acc.push(`${curr.amount} ${patternList[i]}`);
        }

        return acc;
      }, [])
      .join(' ');

    this.text = [
      pattern,
      mainI18n,
      current.mainSpaceNumber
        ? `${current.mainSpaceNumber}${pingI18n}`
        : goodI18n,
      current.price,
      current.age,
    ].filter((x) => !!x);

    if (current.body_short) {
      this.cardBody =
        current.body_short.length > 60
          ? current.body_short.substring(0, 60) + '...'
          : current.body_short;
    }
  }
}
