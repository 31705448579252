<!--  #main id="about" -->
<main class="text-base push-menu">
  <!-- class="w-full h-full bg-cover bg-no-repeat bg-center"
  [style.backgroundImage]="
    '/assets/img/banner.jpeg' | safe: 'background-image'
  " -->
  <!-- background-image: radial-gradient(farthest-side at 217px 123px, #ffa726 , #ffffff 0px, #f4ca8d 49%, #ffbd69 100%);
  } -->
  <div class="bg-primary/20">
    <app-index-carousel />
  </div>

  <!-- info card -->
  <div class="bg-primary pt-12" [class.pb-12]="!searchResult">
    <div class="container">
      <app-search-properties-block
        class="bg-white/50 block px-2 pt-2 rounded-t-2.5xl elevation-4"
        [noInit]="true"
        [ngClass]="{ 'rounded-b-2.5xl pb-2': !searchResult }"
        [searchResult]="searchResult"
        (doSearch)="onSearch($event)"
      />
    </div>
  </div>

  @if (popularTags().length > 0) {
    <div class="container pt-6 pb-8">
      <h2 class="mb-6">
        <span class="under inline-block md:ml-4">
          {{ '熱門分類' | translate }}
        </span>
      </h2>
      <div class="flex flex-wrap gap-3">
        @for (popularTag of popularTags(); track popularTag) {
          <a
            class="bg-primary/10 rounded-full px-4 py-2 text-primary hover:text-white hover:bg-primary border-primary border"
            [routerLink]="popularTag.fullLink | i18nLink"
          >
            {{ popularTag.text }}
          </a>
        }
      </div>
    </div>
  }

  @defer (on immediate) {
    @if (properties$ | async; as result) {
      <div class="bg-primary/20 pt-6">
        <h2 class="mb-6 container">
          <span class="under inline-block md:ml-4">
            {{ '為您精選' | translate }}
          </span>
        </h2>
        <app-properties-result
          class="block container"
          [properties]="result.nodes"
          [totalCount]="result.totalCount"
          (findMore)="goDetail()"
        />
      </div>
    }
    <div class="bg-gray-50 py-12">
      <app-index-blog class="block container" />
    </div>
    <div class="bg-accent text-white py-12">
      <app-index-service class="block container" />
    </div>
  }
</main>
