<ul class="choice-menu font-bold">
  <li class="lg:hidden mt-6 bg-white/50 rounded p-4">
    <img [src]="rootData.info.logo | img | safe: 'url'" class="rounded mr-2" />
  </li>
  <li>
    <a
      [routerLink]="['/'] | i18nLink"
      (click)="_base.toggleMenu()"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      {{ '首頁' | translate }}
    </a>
  </li>
  @for (menu of menus(); track menu.url) {
    <li drop-down-item [menu]="menu"></li>
  }
  <li class="my-2">
    <a
      class="border text-white rounded hover-button p-1 transition"
      [href]="fbLink"
      target="_blank"
    >
      <i class="icon-facebook text-[1.5rem] align-middle"></i>
      {{ '粉絲團' | translate }}
    </a>
  </li>
  <li class="my-2">
    <a
      class="border border-primary text-white rounded hover-button p-1 transition cursor-pointer ml-2"
      [routerLink]="['/page/recruit'] | i18nLink"
      routerLinkActive="active"
    >
      {{ '人才招募' | translate }}
    </a>
  </li>
</ul>
