import { appGlobal } from '@nghedgehog/angular-ui';

export function zoneCompare(target: any, key: string) {
  let value = target[key];
  const getter = () => {
    return value;
  };
  const setter = (newVal) => {
    if (value !== newVal) {
      appGlobal.zone.run(() => {
        value = newVal;
      });
    }
  };
  if (delete target[key]) {
    Object.defineProperty(target, key, {
      get: getter,
      set: setter,
      enumerable: true,
      configurable: true,
    });
  }
}
