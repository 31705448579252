import { AsyncPipe, DOCUMENT } from '@angular/common';
import {
  Component,
  OnInit,
  Renderer2,
  ViewContainerRef,
  ViewEncapsulation,
  inject,
  viewChild,
} from '@angular/core';
import { Meta } from '@angular/platform-browser';

import { map, switchMap, timer } from 'rxjs';

import { PageService } from '@nghedgehog/angular-ui';

import {
  THEME_COLOR_LIST,
  ThemeModel,
  ThemeService,
} from '../../core/components/theme-color-selector';
import { LazyStyleComponent } from './lazy-style/lazy-style.component';

@Component({
  selector: 'app-inline-style',
  template: `
    <ng-container #anchor />
    @defer {
      @if (lazy$ | async) {
        <app-lazy />
      }
    }
  `,
  styleUrls: ['./inline-style.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [LazyStyleComponent, AsyncPipe],
})
export class InlineStyleComponent implements OnInit {
  private _renderer = inject(Renderer2);
  private _theme = inject(ThemeService);
  private _page = inject(PageService);
  public themes = inject<ThemeModel[]>(THEME_COLOR_LIST, { optional: true });
  private document = inject<Document>(DOCUMENT);
  private _meta = inject(Meta);
  anchor = viewChild('anchor', { read: ViewContainerRef });

  // * use that way to make style lazy apply to dom
  lazy$ = timer(100).pipe(map(() => true));

  async ngOnInit() {
    if (this._page.isBrowser) {
      // this event never stop, so not need unsubscribe
      this._theme.currentTheme$
        .pipe(switchMap((theme) => this.loadTheme(theme).then(() => theme)))
        .subscribe();
    } else {
      const theme = this._theme.currentTheme$.value;
      await this.loadTheme(theme);
    }
  }

  private async loadTheme(theme: ThemeModel) {
    const { Component } = await theme.loadChildren();

    this.anchor().createComponent(Component);

    this._meta.updateTag({ name: 'theme-color', content: theme.primary });

    const hostElm = this.document.documentElement;

    this._renderer.removeAttribute(
      hostElm,
      theme.name === 'light' ? 'dark' : 'light',
    );

    this._renderer.setAttribute(hostElm, theme.name, '');
  }
}
