import { AsyncPipe, DOCUMENT } from '@angular/common';
import {
  Component,
  ElementRef,
  HostBinding,
  inject,
  ViewEncapsulation,
  viewChild,
} from '@angular/core';
import { ActivatedRoute, RouterOutlet } from '@angular/router';

import {
  catchError,
  forkJoin,
  map,
  of,
  retry,
  shareReplay,
  startWith,
} from 'rxjs';

import { constants } from '../../../environments/constants';
import { AwooService } from '../../core/service/awoo.service';
import { DepartmentService } from '../../core/service/department.service';
import { FloatMenuComponent } from './components/float-menu/float-menu.component';
import { FooterComponent } from './components/footer/footer.component';
import { MenuModel } from '@alan-apps/data-access';
import { APP_TITLE, BaseService } from '@nghedgehog/angular-ui';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [FloatMenuComponent, RouterOutlet, FooterComponent, AsyncPipe],
})
export class HomeComponent {
  public document = inject<Document>(DOCUMENT);
  private _base = inject(BaseService);
  private departmentService = inject(DepartmentService);
  private _route = inject(ActivatedRoute);
  @HostBinding('class') className = 'flex flex-col overflow-hidden';

  elm = viewChild('containerElm', { read: ElementRef });
  floatMenu = viewChild(FloatMenuComponent);

  private awoo = inject(AwooService);
  private readonly initMenu = this.getMenus();

  menus$ = forkJoin([
    this.departmentService.all(),
    this.awoo.popularTags$,
  ]).pipe(
    map(([departments, popularTags]) => {
      const departmentMenus = departments.map<MenuModel>((department) => {
        return {
          title: department.name,
          url: ['/about', 'department', department.id],
        };
      });

      const popularTagMenus = popularTags.map<MenuModel>((tag) => {
        return {
          title: tag.text,
          url: [tag.fullLink],
          hiddenInFooter: true,
        };
      });

      return this.getMenus({
        departments: departmentMenus,
        tags: popularTagMenus,
      });
    }),
    retry({ count: 1, delay: 1000 }),
    catchError(() => {
      return of(
        this.getMenus({
          departments: [
            {
              title: '小草店',
              url: ['/about', 'department', '00001'],
            },
          ],
          tags: [],
        }),
      );
    }),
    shareReplay(1),
    startWith(this.initMenu),
  );

  private getAppTitle = inject(APP_TITLE);
  title = this.getAppTitle();

  get isHome() {
    return this.floatMenu().isHome;
  }
  get isShowTop() {
    return this.floatMenu().isShowTop;
  }

  constructor() {
    this._base.currentPath = this._route.snapshot.data['rootURL'];
  }

  private getMenus(options?: {
    departments: MenuModel[];
    tags: MenuModel[];
  }): MenuModel[] {
    const { departments = [], tags = [] } = options || {};
    return [
      {
        title: '關於我們',
        url: ['/about'],
        children: [
          {
            title: '我們的故事',
            url: ['/about/introduction'],
          },
          ...(departments.length > 1
            ? [
                {
                  title: '營業處介紹',
                  url: ['/about/department'],
                  children: [...departments],
                },
              ]
            : []),
          {
            title: '經理人專區',
            url: ['/about/agent'],
          },
        ],
      },
      {
        title: '物件搜尋',
        url: ['/properties'],
        children: [
          {
            title: '物件搜尋',
            url: ['/properties', 'page'],
          },
          {
            title: '進階搜尋',
            url: ['/properties', 'advance'],
            children: [
              // {
              //   title: '快速搜尋',
              //   url: ['/properties', 'advance'],
              //   queryParams: { type: 'quick' }
              // },
              {
                title: '進階詳細搜尋',
                url: ['/properties', 'advance'],
                queryParams: { type: 'detail' },
              },
              {
                title: '捷運搜尋',
                url: ['/properties', 'advance'],
                queryParams: { type: 'mrt' },
              },
            ],
          },
          ...(tags.length > 1
            ? [
                {
                  title: '熱門分類',
                  url: ['/tags'],
                  children: [...tags],
                },
              ]
            : []),
          {
            title: '生活圈搜尋',
            url: ['/live-area', 'page'],
          },
          // {
          //   title: '找社區',
          //   url: ['/community', 'page'],
          // },
        ],
      },
      {
        title: '線上委託',
        url: ['/entrust'],
        children: [
          {
            title: '線上委託',
            url: ['/entrust'],
          },
          {
            title: '租金扣繳試算',
            url: ['/calculator'],
          },
        ],
      },
      {
        title: '商家推薦',
        url: ['/company'],
      },
      {
        title: '好文分享',
        url: ['/blog'],
      },
    ];
  }

  // getRouteAnimationData() {
  //   const snapshot = this.contexts.getContext('primary')?.route?.snapshot;
  //   const animate = snapshot?.data?.['animation'];
  //   return animate || this._route.snapshot.url;
  // }
}
