import { inject } from '@angular/core';

import { filter, map, take, tap } from 'rxjs';

import { ThemeService } from '../components/theme-color-selector';

export const styleGuard = () => {
  const theme = inject(ThemeService);
  theme.init();

  return theme.currentTheme$.pipe(
    filter(Boolean),
    take(1),
    tap((theme) => theme.loadChildren()),
    map(() => true),
  );
};
