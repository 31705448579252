import { NgClass } from '@angular/common';
import {
  AfterViewInit,
  Component,
  TemplateRef,
  inject,
  viewChild,
} from '@angular/core';

import { LoadingBlockDirective, SafePipe } from '@nghedgehog/angular-ui';

import { ImgPipe } from '../../../pipe/img.pipe';
import { MyGalleryService } from '../my-gallery.service';

@Component({
  selector: 'app-photo-gallery',
  templateUrl: './photo-gallery.component.html',
  styleUrls: ['./photo-gallery.component.scss'],
  standalone: true,
  imports: [LoadingBlockDirective, NgClass, SafePipe, ImgPipe],
})
export class PhotoGalleryComponent implements AfterViewInit {
  private _gallery = inject(MyGalleryService);
  item = viewChild('item', { read: TemplateRef });
  gallery = viewChild('gallery', { read: TemplateRef });

  ngAfterViewInit() {
    this._gallery.setTemplate(this.item(), this.gallery());
  }
}
