<div class="flex items-center cursor-pointer" [matMenuTriggerFor]="menu">
  <i class="material-icons mat-size-1">language</i>
  <span class="mx-2 text-xs mb-1">
    {{ '繁體中文' | translate }}
  </span>
  <i class="material-icons mat-size-1">keyboard_arrow_down</i>
</div>
<mat-menu #menu="matMenu">
  @for (language of languages; track language) {
  <button
    mat-menu-item
    (click)="changeLan(language.id)"
    [ngClass]="{ 'bg-gray-200/50': currentLang === language.id }"
  >
    <div class="flex items-center gap-2">
      <!--
        <div class="flag flag-icon-background" [attr.data-lang]="language.icon">
          @switch (language.icon) { @case ('us') {
            <us></us>
            } @case ('cn') {
            <cn></cn>
            } @case ('tw') {
            <tw></tw>
            } @case ('jp') {
            <jp></jp>
            } }
          </div>
          -->
      <div
        class="flag flag-icon-background flag-icon-{{ language.icon }}"
        [attr.data-lang]="language.icon"
      ></div>
      <span>
        {{ language.title }}
      </span>
    </div>
  </button>
  }
</mat-menu>
