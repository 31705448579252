import { NgClass } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';

import { I18nService } from '@nghedgehog/angular-ui';
import { TranslateModule } from '@ngx-translate/core';

// @Component({
//   selector: 'us',
//   templateUrl: './flags/4x3/us.svg',
//   standalone: true,
// })
// export class EN_USComponent {}

// @Component({
//   selector: 'cn',
//   templateUrl: './flags/4x3/cn.svg',
//   standalone: true,
// })
// export class CNComponent {}
// @Component({
//   selector: 'tw',
//   templateUrl: './flags/4x3/tw.svg',
//   standalone: true,
// })
// export class TWComponent {}
// @Component({
//   selector: 'jp',
//   templateUrl: './flags/4x3/jp.svg',
//   standalone: true,
// })
// export class JPComponent {}

@Component({
  selector: 'app-i18n-menu',
  templateUrl: './i18n-menu.component.html',
  styleUrls: ['./i18n-menu.component.scss'],
  standalone: true,
  imports: [MatMenuModule, TranslateModule, NgClass],
})
export class I18nMenuComponent {
  private _i18n = inject(I18nService);
  languages = this._i18n.languages;

  get currentLang() {
    return this._i18n.translate.currentLang;
  }

  changeLan(lang: string) {
    this._i18n.setLang(lang, { reload: true });
  }
}
