<a
  [routerLink]="menu().url | i18nLink"
  [queryParams]="menu().queryParams"
  routerLinkActive="active"
  [class.has_child]="hasChild"
  class="transition h6"
>
  {{ menu().title | translate }}
</a>

@if (hasChild) {
  <ul>
    @for (menuItem of children; track menuItem.url) {
      <li footer-link [menu]="menuItem"></li>
    }
  </ul>
}
