import { AsyncPipe } from '@angular/common';
import { Component, inject } from '@angular/core';

import { BaseService } from '@nghedgehog/angular-ui';

@Component({
  selector: 'app-menu-icon',
  templateUrl: './menu-icon.component.html',
  styleUrls: ['./menu-icon.component.scss'],
  standalone: true,
  imports: [AsyncPipe],
})
export class MenuIconComponent {
  public _base = inject(BaseService);
}
