import { AsyncPipe, NgClass } from '@angular/common';
import { Component, HostBinding, inject, input } from '@angular/core';
import { RouterLink } from '@angular/router';

import { Observable } from 'rxjs';

import { Property } from '@alan-apps/data-access';
import { I18nLinkPipe } from '@nghedgehog/angular-ui';
import { TranslateModule } from '@ngx-translate/core';

import { SearchPropertiesBlockComponent } from '../../../../core/components/search/search-properties-block/search-properties-block.component';
import { AwooService, Tag } from '../../../../core/service/awoo.service';
import { PropertyService } from '../../../../core/service/property.service';
import { IndexBlogComponent } from './index-blog/index-blog.component';
import { IndexCarouselComponent } from './index-carousel/index-carousel.component';
import { IndexServiceComponent } from './index-service/index-service.component';
import { PropertiesResultComponent } from './properties-result/properties-result.component';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss'],
  standalone: true,
  imports: [
    IndexCarouselComponent,
    SearchPropertiesBlockComponent,
    NgClass,
    PropertiesResultComponent,
    IndexBlogComponent,
    IndexServiceComponent,
    AsyncPipe,
    RouterLink,
    I18nLinkPipe,
    TranslateModule,
  ],
})
export class IndexComponent {
  @HostBinding('class') className = 'w-full';
  popularTags = input<Tag[] | undefined>();
  private awoo = inject(AwooService);
  private property = inject(PropertyService);

  constructor() {
    (window as any).awoo = this.awoo;
  }

  searchResult: PaginatorResult<Property[]>;
  saveQuery = {};

  properties$ = this.property.list(
    { skip: 0, take: 10 },
    {
      featured: true,
    },
  ) as Observable<PaginatorResult<Property[]>>;

  onSearch(query) {
    // this.saveQuery = this._property.searchNoRedirect(query);
    this.property.searchNoRedirect(query);
    this.property.search(query);

    // this.find();
  }

  goDetail() {
    // TODO:
    this.property.goMore({
      // featured: true,
    });
  }
}
